import React, { FC, useState, useEffect, useRef } from "react";
import { graphql, navigate } from "gatsby";
import Image, { FluidObject } from "gatsby-image";
import { WerkTemplateQuery } from "../../graphql-types";
import useFilteredWerke, { Werk } from "../hooks/useFilteredWerke";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import { RecordParam } from "../utils/RecordParam";
import SEO from "../components/Seo";
import Lightbox from "react-image-lightbox";
// @ts-ignore
import useKeypress from "react-use-keypress";
import { ArrowLeft, ArrowRight, CloseIcon, Hamburger } from "../components/Icons";
import { useQueryParam, JsonParam, BooleanParam } from "use-query-params";
import useWindowSize from "../hooks/useWindowSize";
import useLocalStorage from "../hooks/useLocalStorage";

const GridContainer = styled.div`
  display: grid;
  grid-gap: var(--s4);
  padding: 0 var(--s12);
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    padding: 0 var(--s4);
  }
`;

const NavBarContainer = styled.div`
  padding: 0 var(--s12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding: 0 var(--s4);
  }
  div {
    button {
      background: none;
      border: none;
      cursor: pointer;
      svg {
        transition: all 0.3s;
        width: var(--s12);
        height: var(--s12);
        fill: var(--grey);
        &:hover {
          fill: var(--black);
        }
      }
    }
  }
`;

const Content = styled.div`
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  p,
  h2,
  div {
    color: var(--black);
    font-weight: 300;
  }

  .datumContainer {
    margin-top: var(--s16);
  }

  .massContainer {
    margin-top: var(--s16);
  }
  h2 {
    font-size: 2rem;
    line-height: 3.3rem;
    @media (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .copyright {
    font-size: 1.6rem;
    line-height: 2.4rem;
    /* position: absolute; */
    bottom: 0;
    @media screen and (max-width: 700px) {
      margin-bottom: 20px;
      /* position: static; */
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
  .left,
  h2 {
    font-size: 2rem;
    line-height: 3.3rem;
    @media (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
  .right {
    margin-top: var(--s16);
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .left {
    margin-top: var(--s6);
  }

  .meta1Container {
    margin-top: var(--s16);
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    @media screen and (max-width: 700px) {
      margin-top: 0;
    }
  }

  .meta2Container {
    padding-top: var(--s16);
  }

  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const ImageWrapper = styled("button")<{ format: "quer" | "hoch" }>`
  border: none;
  background: none;
  grid-column: 2 / -1;
  width: 100%;
  justify-self: end;
  outline: none;
  align-self: ${props => (props.format === "quer" ? "start" : "initial")};
  @media screen and (max-width: 700px) {
    grid-column: auto;
    order: -1;
    max-height: 100vh;
  }
`;

const StyledImage = styled(Image)`
  max-height: calc(100vh - 6rem - 6rem - 6rem);
  @media screen and (max-width: 700px) {
    max-height: 100vh;
  }
`;

const WerkTemplate: FC<{ data: WerkTemplateQuery; path: string }> = props => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [ascending] = useQueryParam("sortDirection", BooleanParam);
  const werkeRaw = useFilteredWerke({ ascending: ascending ? ascending : false });
  const [query] = useLocalStorage("filter", {});
  const werke = filterWerke(query, werkeRaw);
  const { width } = useWindowSize();
  const [werkIndex, setWerkIndex] = useState(() =>
    werke.findIndex(w => w.internenummer.toString() === props.path.substring(1))
  );
  function filterWerke(query: any, initialWerke: Werk[]) {
    // @ts-ignore
    if (!query || Object.keys(query).length === 0 || Object.values(query).every((arr: any[]) => arr.length === 0)) {
      return initialWerke;
    }

    const selection = query;
    const result = initialWerke.filter(werk =>
      Object.keys(selection).every(key => {
        // hack since this werk somehow always gets displayed
        if (werk.internenummer === "1568") {
          return false;
        }

        if (selection[key].length === 0) {
          return true;
        }
        // @ts-ignore
        if (!werk[key]) {
          return false;
        }
        // @ts-ignore
        if (Array.isArray(werk[key])) {
          // @ts-ignore
          return werk[key].some(k => selection[key].includes(k));
        }
        // @ts-ignore
        return selection[key].includes(werk[key]);
      })
    );
    return result;
  }
  useKeypress(["ArrowLeft", "ArrowRight"], (event: any) => {
    if (isLightboxOpen) return;
    if (event.key === "ArrowLeft") {
      navigateLeft();
    } else {
      navigateRight();
    }
  });

  const werk = werke[werkIndex];

  function handleClose() {
    setIsLightboxOpen(false);
    setWerkIndex(werke.findIndex(w => w.internenummer.toString() === props.path.substring(1)));
    navigate(`/${werke[werkIndex].internenummer}/?sortDirection=${Number(ascending)}`);
  }

  function navigateLeft() {
    if (!isLightboxOpen) {
      navigate(`/${werke[(werkIndex + werke.length - 1) % werke.length].internenummer}/?sortDirection=${Number(ascending)}`);
    }
  }

  function navigateRight() {
    if (isLightboxOpen) return;
    if (!isLightboxOpen) {
      navigate(`/${werke[(werkIndex + 1) % werke.length].internenummer}/?sortDirection=${Number(ascending)}`);
    }
  }

  function handleBack() {
    navigate(Routes.WERKVERZEICHNIS + `/?sortDirection=${Number(ascending)}`);
  }

  if (werk) {
    return (
      <Layout activePage={Routes.WERK}>
        <SEO title={`Werk ${werk.internenummer}`} />
        {isLightboxOpen ? (
          <Lightbox
            reactModalStyle={{ overlay: { background: "black", opacity: 1 } }}
            mainSrc={werk.fluid.src}
            nextSrc={werke[(werkIndex + 1) % werke.length].fluid.src}
            prevSrc={werke[(werkIndex + werke.length - 1) % werke.length].fluid.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={() => setWerkIndex((werkIndex + werke.length - 1) % werke.length)}
            onMoveNextRequest={() => setWerkIndex((werkIndex + 1) % werke.length)}
          />
        ) : (
          <>
            <NavBarContainer>
              <div>
                <button onClick={navigateLeft}>
                  <ArrowLeft />
                </button>
                <button style={{ marginLeft: "0.4rem" }} onClick={navigateRight}>
                  <ArrowRight />
                </button>
              </div>
              <div>
                <button onClick={handleBack}>
                  <CloseIcon />
                </button>
              </div>
            </NavBarContainer>
            <GridContainer>
              <Content>
                <div className="left">
                  <h2>{werk.titel && werk.titel}</h2>
                  <div className="datumContainer">
                    <p>{werk.displayDate ? werk.displayDate : "Ohne Datum"}</p>
                    <p>{werk.datum2 && werk.datum2.toISOString().slice(0, 10)}</p>
                    <p>{werk.datum3 && werk.datum3.toISOString().slice(0, 10)}</p>
                    <p>{werk.datum4 && werk.datum4.toISOString().slice(0, 10)}</p>
                    <p>{werk.datum5 && werk.datum5.toISOString().slice(0, 10)}</p>
                    <p>{werk.datum6 && werk.datum6.toISOString().slice(0, 10)}</p>
                    <p>{werk.datum7 && werk.datum7.toISOString().slice(0, 10)}</p>
                    <div className="massContainer">{werk.breite && werk.hoehe && `${werk.hoehe} x ${werk.breite}`} cm</div>
                  </div>
                </div>
                <div className="right">
                  <div className="meta1Container">
                    <p>
                      Technik:&nbsp;
                      <span>{werk.technik && werk.technik.join(", ")}</span>
                    </p>
                    <p>
                      Montage:&nbsp;
                      <span>{werk.original && werk.original}</span>
                    </p>
                    <p>
                      Standort:&nbsp;
                      <span>{werk.standort && werk.standort}</span>
                    </p>
                    <p>
                      Reg. Nr.:&nbsp;
                      <span>{werk.internenummer && werk.internenummer}</span>
                    </p>
                    {werk.thema.length > 0 &&
                      (werk.thema.length > 1 ? (
                        <>
                          <p>Thema: {werk.thema.join(", ")}</p>
                        </>
                      ) : (
                        <p>Thema: {werk.thema[0]}</p>
                      ))}
                    {werk.interneBezeichnung && (
                      <p>Interne Bezeichnung: {werk.interneBezeichnung && werk.interneBezeichnung}</p>
                    )}
                    <br />
                    <p className="copyright">Für die Werke: © Nachlass Bruno Heller</p>
                  </div>
                </div>
              </Content>
              {props.data.file && (
                <ImageWrapper format={werk.format} onClick={() => setIsLightboxOpen(true)}>
                  <StyledImage
                    imgStyle={{
                      objectFit: "contain",
                      objectPosition: width && width < 800 ? "50% 50%" : "right",
                      cursor: "pointer",
                    }}
                    fluid={props.data.file.childImageSharp!.fluid as any}
                  />
                </ImageWrapper>
              )}
            </GridContainer>
          </>
        )}
      </Layout>
    );
  }
  return null;
};

export default WerkTemplate;

export const query = graphql`
  query WerkTemplate($id: String!) {
    file(id: { eq: $id }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
